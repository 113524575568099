


























































import Vue from "vue";
import DataNotFound from "@/components/shared/DataNotFound.vue";
import { ViewJobState } from "@/store/modules/common/interfaces";
import { mapGetters } from "vuex";
import { VIEW_JOB_STATE } from "@/store/modules/common/constants";

export default Vue.extend({
  name: "JobDescriptionCard",
  computed: {
    ViewJobState() {
      return ViewJobState;
    },
    ...mapGetters("common", {
      view_job_state: VIEW_JOB_STATE
    })
  },
  components: { DataNotFound },
  data() {
    return {
      description_copy_snackbar: false
    };
  },
  props: {
    description: {
      type: String
    }
  },
  methods: {
    async copy_title() {
      await navigator.clipboard.writeText(this.description);
      this.description_copy_snackbar = true;
    }
  }
});
