































































































































































































































































































































import Vue from "vue";
import { SidebarLinks } from "@/interfaces/candidate/nav_side_bar";
import ChangeLanguage from "@/components/shared/ChangeLanguage.vue";
import { mapActions, mapGetters } from "vuex";
import {
  AUTH_LOADING,
  GET_LATEST_WORK_HISTORY,
  GET_USER_DETAILS,
  LOGOUT_USER
} from "@/store/modules/auth/constants";
import {
  clean_string,
  extract_translation_from_key,
  get_name_initials
} from "@/utils/global";
import {
  GET_ROLE_BY_NAME,
  INIT_NOTIFICATIONS,
  NOTIFICATION_ACTION,
  NOTIFICATION_ACTION_LOADING,
  NOTIFICATIONS,
  NOTIFICATIONS_COUNT,
  SITE_DIRECTION
} from "@/store/modules/common/constants";
import DataNotFound from "@/components/shared/DataNotFound.vue";
import {
  NotificationEventDetails,
  NotificationsResponse
} from "@/interfaces/responses/notifications";
import {
  UpdateNotificationStatusPayload,
  SiteDirections
} from "@/store/modules/common/interfaces";
import { IApp } from "@/interfaces";
import { SidebarContent } from "@/content";

export default Vue.extend({
  name: "NavSideBar",
  components: { DataNotFound, ChangeLanguage },
  data() {
    return {
      notification_overlay: false,
      module: "",
      drawer: true,
      dropdown_icon: require("@/assets/icons/linear/direction-down.svg"),
      notification_icon: require("@/assets/icons/linear/notification.svg"),
      active_route_icon: require("@/assets/icons/linear/speed-test.svg")
    };
  },
  watch: {
    "$vuetify.breakpoint.mdAndDown"(isSmallScreen) {
      if (isSmallScreen) {
        this.drawer = false;
      } else {
        this.drawer = true;
      }
    }
  },
  created() {
    if (this.$vuetify.breakpoint.mdAndDown) {
      this.drawer = false;
    }
  },
  computed: {
    SiteDirections() {
      return SiteDirections;
    },
    AppPortal(): IApp.Portals {
      return process.env.VUE_APP_PORTAL;
    },
    Portals() {
      return IApp.Portals;
    },
    ...mapGetters("auth", {
      user: GET_USER_DETAILS,
      get_latest_work_history: GET_LATEST_WORK_HISTORY,
      auth_loading: AUTH_LOADING
    }),
    ...mapGetters("common", {
      get_role_by_name: GET_ROLE_BY_NAME,
      get_notifications_count: NOTIFICATIONS_COUNT,
      get_notifications: NOTIFICATIONS,
      notification_action_loading: NOTIFICATION_ACTION_LOADING,
      get_site_direction: SITE_DIRECTION
    }),
    SidebarContent(): SidebarLinks[] {
      return SidebarContent;
    }
  },
  async mounted() {
    if (this.get_role_by_name("recruiter").id === this.user.role_id) {
      this.module = "recruiter";
    } else if (
      this.get_role_by_name("recruiter admin").id === this.user.role_id
    ) {
      this.module = "recruiter_admin";
    } else this.module = "candidate";
    await this.init_notifications();
  },
  methods: {
    clean_string,
    get_name_initials,
    extract_translation_from_key,
    ...mapActions("auth", {
      logout_user: LOGOUT_USER
    }),
    async _logout_user() {
      await this.logout_user();
      await this.$router.push("/auth/login");
    },
    ...mapActions("common", {
      init_notifications: INIT_NOTIFICATIONS,
      notification_action: NOTIFICATION_ACTION
    }),
    async mark_all_as_read() {
      const ids = this.get_notifications.map(
        (notification: NotificationsResponse) => notification.id
      );
      const payload: UpdateNotificationStatusPayload = {
        notificationIds: ids
      };
      await this.notification_action(payload);
    },
    async dismiss_notification(notification: NotificationsResponse) {
      const payload: UpdateNotificationStatusPayload = {
        notificationIds: [notification.id]
      };
      await this.notification_action(payload);
    },
    async notification_navigate(notification: NotificationsResponse) {
      const event_details = NotificationEventDetails[notification.event_name];
      let route: string = event_details.route;
      if (event_details.navigation) route += `${notification.event_id}`;
      await this.$router.push(route);
      await this.dismiss_notification(notification);
      this.toggle_notification_overlay();
    },
    toggle_notification_overlay() {
      this.notification_overlay = !this.notification_overlay;
    }
  }
});
